<template>
  <base-section
    id="about-our-product"
    class="pb-0"
  >
    <base-section-heading
      title="Partner with Us"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="3"
        >
          <base-info-card
            v-bind="card"
            align="center"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',

    data: () => ({
      cards: [
        {
          title: 'international standard',
          subtitle: '',
          text: '',
          callout: '',
          icon: 'mdi-certificate',
        },
        {
          title: 'premium quality',
          subtitle: '',
          text: '',
          callout: '',
          icon: 'mdi-star-outline',
        },
        {
          title: 'House brands & oem products',
          subtitle: '',
          text: '',
          callout: '',
          icon: 'mdi-lightbulb-on-outline',
        },
        {
          title: 'routes to market',
          subtitle: '',
          text: '',
          callout: '',
          icon: 'mdi-earth',
        },
      ],
    }),
  }
</script>
